import { SvgIconConstituentValues } from "./icons.type";

export default function AppearanceManagementIcon(props: SvgIconConstituentValues) {
  return (
    <svg
      id="category_black_24dp"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path id="Path_3732" data-name="Path 3732" d="M0,0H24V24H0Z" fill="none" />
      <path
        id="Path_3733"
        data-name="Path 3733"
        d="M12,2,6.5,11h11Zm0,3.84L13.93,9H10.06ZM17.5,13A4.5,4.5,0,1,0,22,17.5,4.494,4.494,0,0,0,17.5,13Zm0,7A2.5,2.5,0,1,1,20,17.5,2.5,2.5,0,0,1,17.5,20ZM3,21.5h8v-8H3Zm2-6H9v4H5Z"
        fill="#fff"
      />
    </svg>
  );
}
