import { createSlice } from "@reduxjs/toolkit";
import { OptionType } from "src/components";

export interface InitialOptionsDropdownStateType {
  memberTiers: OptionType[];
  memberType: OptionType[];
  orderType: OptionType[];
  couponSuggestions: string[];
}

const initialState: InitialOptionsDropdownStateType = {
  memberTiers: [
    {
      value: "Al",
      title: "all_tiers",
    },
  ],
  orderType: [
    {
      title: "all_types",
      value: "ALL",
    },
    {
      title: "ROC",
      value: "ROC",
    },
    {
      title: "BMC",
      value: "BMC",
    },
  ],
  memberType: [
    {
      title: "all_types",
      value: "ALL_TYPE",
    },
    {
      title: "SCM_member",
      value: "SCM_MEMBER",
    },
    {
      title: "SCM_business",
      value: "SCM_BUSINESS",
    },
  ],
  couponSuggestions: [],
};

const optionsDropdownSlice = createSlice({
  name: "optionsDropdown",
  initialState,
  reducers: {
    setMemberTier: (state, action) => {
      state.memberTiers = action.payload;
    },
    setCouponSuggestions: (state, action) => {
      state.couponSuggestions = action.payload;
    },
  },
});

export const { setMemberTier, setCouponSuggestions } = optionsDropdownSlice.actions;
export default optionsDropdownSlice.reducer;
