import { SvgIconConstituentValues } from "./icons.type";

export default function ClockBlack(props: SvgIconConstituentValues) {
  return (
    <svg
      id="operating_time_black_24dp"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <path id="Path_2071" data-name="Path 2071" d="M0,0H24V24H0Z" fill="none" />
      <path
        id="Path_2072"
        data-name="Path 2072"
        d="M11.99,2A10,10,0,1,0,22,12,10,10,0,0,0,11.99,2ZM12,20a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"
        fill="#231f20"
      />
      <path
        id="Path_2073"
        data-name="Path 2073"
        d="M12.5,7H11v6l5.25,3.15L17,14.92l-4.5-2.67Z"
        fill="#231f20"
      />
    </svg>
  );
}
