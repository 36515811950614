import { SvgIconConstituentValues } from "./icons.type";

export default function CopyIcon(props: SvgIconConstituentValues) {
  return (
    <svg
      id="copy_black_24dp"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <path id="Path_3768" data-name="Path 3768" d="M0,0H24V24H0Z" fill="none" />
      <path
        id="Path_3769"
        data-name="Path 3769"
        d="M16,1H4A2.006,2.006,0,0,0,2,3V17H4V3H16Zm3,4H8A2.006,2.006,0,0,0,6,7V21a2.006,2.006,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V7A2.006,2.006,0,0,0,19,5Zm0,16H8V7H19Z"
        fill="#000"
      />
    </svg>
  );
}
