import { SvgIconConstituentValues } from "./icons.type";

export default function ChevronUp(props: SvgIconConstituentValues) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18.484"
      height="18.484"
      viewBox="0 0 18.484 18.484"
      {...props}
    >
      <g id="arrow_down_black_24dp" transform="translate(0 0)">
        <path
          id="Path_2069"
          data-name="Path 2069"
          d="M0,18.484H18.484V0H0Z"
          transform="translate(0 0)"
          fill="none"
        />
        <path
          id="Path_2070"
          data-name="Path 2070"
          d="M7.086,14.3l3.535-3.527L14.156,14.3l1.086-1.086L10.621,8.59,6,13.211Z"
          transform="translate(-1.379 -2.429)"
        />
      </g>
    </svg>
  );
}
