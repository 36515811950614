import { SvgIconConstituentValues } from "./icons.type";
// width="40"
//       height="17.086"
//       viewBox="0 0 40 17.086"
export default function ImageErrorIcon(props: SvgIconConstituentValues) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 852.26 311.85" width="40px" height="17px">
      <defs>
        <style>{/* .cls-1{fill:#bcbcbc;}.cls-2{fill:#bcbcbc;} */}</style>
      </defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path
            style={{ fill: "#bcbcbc" }}
            d="M22.84,117.21a39.49,39.49,0,0,0,6.23,3.58,92.1,92.1,0,0,0,12.23,4.84A151.23,151.23,0,0,0,58.38,130a105.67,105.67,0,0,0,21,2,68.47,68.47,0,0,0,14.77-1.27,25,25,0,0,0,8.54-3.34,9.81,9.81,0,0,0,3.92-4.74,16.46,16.46,0,0,0,.92-5.42,11.15,11.15,0,0,0-4.5-9.11q-4.5-3.57-16.72-5.65L62.76,98.29A133.9,133.9,0,0,1,44.3,93.68a58.39,58.39,0,0,1-16.5-8.31A41.68,41.68,0,0,1,15.92,71.76q-4.62-8.31-4.61-20.54a51,51,0,0,1,3.92-20A43.15,43.15,0,0,1,27.23,15,59.15,59.15,0,0,1,47.65,4Q60,0,76.6,0a153.25,153.25,0,0,1,21,1.27,116.91,116.91,0,0,1,15.46,3.11,77.37,77.37,0,0,1,10.5,3.7,55.6,55.6,0,0,1,5.88,3,31,31,0,0,1,7.5,6.34A14,14,0,0,1,140.52,27a20.41,20.41,0,0,1-1.39,7.49,35.3,35.3,0,0,1-3.34,6.58,29,29,0,0,1-4.27,5.19,38.58,38.58,0,0,1-3.92,3.35,40.1,40.1,0,0,0-6.92-5.19,57.28,57.28,0,0,0-11.08-5.2A103.58,103.58,0,0,0,94.83,35.3a93.52,93.52,0,0,0-17.76-1.61,54.58,54.58,0,0,0-14.31,1.5,22,22,0,0,0-8,3.69,10.11,10.11,0,0,0-3.46,5,18.24,18.24,0,0,0-.81,5.08,12.91,12.91,0,0,0,.58,3.92,7.58,7.58,0,0,0,2.77,3.57,22.61,22.61,0,0,0,6.23,3.23,73,73,0,0,0,11,2.89l24.46,4.84a135.61,135.61,0,0,1,22.72,6.35,59.4,59.4,0,0,1,16.5,9.34,35.06,35.06,0,0,1,10,13.39,47.65,47.65,0,0,1,3.34,18.69q0,24.22-18.11,37.84T78.91,166.59a139.64,139.64,0,0,1-27.57-2.54,163.53,163.53,0,0,1-22-5.88A130.4,130.4,0,0,1,14,151.82c-3.92-2-6.27-3.38-7-4.15Z"
          />
          <path
            style={{ fill: "#bcbcbc" }}
            d="M309.64,150.67a75.14,75.14,0,0,1-19.15,9.69,115.59,115.59,0,0,1-16.84,4.38,117.85,117.85,0,0,1-21.92,1.85A102.2,102.2,0,0,1,219,161.4a77.08,77.08,0,0,1-27.12-15.58A73.9,73.9,0,0,1,173.4,120q-6.81-15.45-6.81-36,0-20.08,6.57-35.65a76.36,76.36,0,0,1,18-26.3A78,78,0,0,1,217.93,5.65,95.22,95.22,0,0,1,251,0a115.87,115.87,0,0,1,17.07,1.15,92.11,92.11,0,0,1,13.5,3,69.09,69.09,0,0,1,10,3.93,75.76,75.76,0,0,1,6.69,3.69A25.85,25.85,0,0,1,306,20a18.44,18.44,0,0,1,2.31,8.88,20.42,20.42,0,0,1-1.73,8.31,33.31,33.31,0,0,1-4.15,7,41.76,41.76,0,0,1-4.85,5.3,24.5,24.5,0,0,1-4,3.12q-2.31-2.31-5.88-5.42A41.49,41.49,0,0,0,279,41.53a66.11,66.11,0,0,0-11.66-4.38,54.12,54.12,0,0,0-14.65-1.85,49,49,0,0,0-18.46,3.35,41.22,41.22,0,0,0-14.42,9.69,43.84,43.84,0,0,0-9.46,15.34A58,58,0,0,0,207,84a52,52,0,0,0,3.69,20.3,40.89,40.89,0,0,0,10.15,14.65,42.71,42.71,0,0,0,15.35,8.89,59.82,59.82,0,0,0,19.26,3q12.91,0,22.27-3.23t14.19-6.7l17.3,29.77Z"
          />
          <path
            style={{ fill: "#bcbcbc" }}
            d="M335,24a23.72,23.72,0,0,1,2.19-9.69,16,16,0,0,1,5.08-6.12,20,20,0,0,1,7-3.11,35.84,35.84,0,0,1,8.3-.93,40.46,40.46,0,0,1,15.23,2.54c4.16,1.7,6.92,3.16,8.31,4.39q8.31,17.07,17.42,36.34t18.57,37.26q8.31-16.62,16.27-32.76t14.65-30.69q4.38-9.92,10.73-13.5a28.91,28.91,0,0,1,14.42-3.58,44.23,44.23,0,0,1,15.35,2.43Q495.15,9,497,10.38l8.77,152.06H467l-5.3-94.6-8.54,17.53q-4.4,9-8.42,17.77t-7.62,16.73q-3.57,7.95-6.34,14.19H402.17q-2.55-6.24-6.35-14.54t-8-17.31q-4.16-9-8.54-17.88t-8.07-16.26l-5.08,94.37h-39Z"
          />
          <path
            style={{ fill: "#bcbcbc" }}
            d="M238.72,242.06A47.93,47.93,0,0,1,243,221.48a44.84,44.84,0,0,1,11.73-15.4,52.2,52.2,0,0,1,17.7-9.66,72.08,72.08,0,0,1,22.42-3.33,73.15,73.15,0,0,1,22.53,3.33,52,52,0,0,1,17.82,9.66,43.56,43.56,0,0,1,11.61,15.4,49.12,49.12,0,0,1,4.14,20.58v69.67H324.71v-63q0-15.63-7.59-23.8t-22.3-8.16q-14.94,0-22.42,8.16t-7.47,23.8v63H238.72Z"
          />
          <path
            style={{ fill: "#bcbcbc" }}
            d="M377.59,242.06a48.08,48.08,0,0,1,4.25-20.58,45,45,0,0,1,11.73-15.4,52.2,52.2,0,0,1,17.7-9.66,72.08,72.08,0,0,1,22.42-3.33,73.22,73.22,0,0,1,22.54,3.33A52.07,52.07,0,0,1,474,206.08a43.7,43.7,0,0,1,11.62,15.4,49.12,49.12,0,0,1,4.14,20.58v69.67H463.58v-63q0-15.63-7.58-23.8t-22.31-8.16q-14.94,0-22.42,8.16t-7.47,23.8v63H377.59Z"
          />
          <path
            style={{ fill: "#bcbcbc" }}
            d="M617.87,300.31q-1.79,1.13-5.59,3.13a56.43,56.43,0,0,1-9.51,3.81,106.38,106.38,0,0,1-13.21,3.13,93.16,93.16,0,0,1-16.46,1.35,70.18,70.18,0,0,1-23.39-3.81,54.18,54.18,0,0,1-19-11.3,53.35,53.35,0,0,1-12.88-18.36,62.33,62.33,0,0,1-4.7-25,63.47,63.47,0,0,1,4.59-24.51,56.11,56.11,0,0,1,12.76-19,57.59,57.59,0,0,1,19.25-12.32A65.71,65.71,0,0,1,574,193.09a64.51,64.51,0,0,1,20.6,3,47.55,47.55,0,0,1,15.1,8.06,32.91,32.91,0,0,1,12.43,26.08,33.84,33.84,0,0,1-2.91,14.21q-2.91,6.38-10.3,11T589,262.59q-12.54,2.58-31.79,2.58-4.47,0-8.39-.11c-2.62-.08-5.41-.19-8.4-.34a27.85,27.85,0,0,0,11.08,18.47q9.29,7.05,26.53,7.05a60.65,60.65,0,0,0,19.59-3.14,63.13,63.13,0,0,0,6.71-2.68q2.8-1.35,4.15-2ZM539.3,247.26q5.16.23,9.85.22h9.41a102.84,102.84,0,0,0,19.92-1.56,40.58,40.58,0,0,0,11.86-4c2.84-1.64,4.7-3.51,5.6-5.6a16.4,16.4,0,0,0,1.34-6.49q0-8.51-6.83-12.76t-16.9-4.25A34.23,34.23,0,0,0,559,215.7a31.57,31.57,0,0,0-10.52,7.72,35.41,35.41,0,0,0-6.6,11.08A40,40,0,0,0,539.3,247.26Z"
          />
          <path
            style={{ fill: "#bcbcbc" }}
            d="M627.25,311.73q1.18-1.67,5.7-7.24T644,291q6.54-7.95,14.49-17.57T674.74,254q-11.16-12.1-19.11-20.06t-12.94-13.41a59.43,59.43,0,0,1-7.36-9.5A15.73,15.73,0,0,1,633,203a10.2,10.2,0,0,1,3.09-7.25q3.09-3.19,10.21-3.2a24.61,24.61,0,0,1,7.6,1.07,39.94,39.94,0,0,1,4.27,1.54c.63.79,2.1,2.49,4.39,5.11s5.07,5.73,8.32,9.37,6.68,7.6,10.32,11.88,7.28,8.39,10.93,12.34q4.5-5.7,9.14-11.16t8.42-10.09q3.81-4.64,6.53-7.83c1.82-2.14,3-3.52,3.45-4.16a29.82,29.82,0,0,1,7.59-6.05,18.93,18.93,0,0,1,9-2,22.46,22.46,0,0,1,11.39,2.73c3.17,1.82,5.15,3.29,5.94,4.39q-1.91,2.15-6.29,7.24T737,219l-13.18,15.55q-7.24,8.55-14.84,17.57,8.55,10,16.86,19.47t15.31,17.57q7,8.07,12.11,14t7,8.55H726.74q-5.46-6.41-14.13-16.5T691.84,271.6q-10.7,12.82-19.59,23.63t-13.18,16.5Z"
          />
          <path
            style={{ fill: "#bcbcbc" }}
            d="M773.41,171.42q0-6.48,3.71-9.39t10.21-2.9a27.64,27.64,0,0,1,7.77,1.05c2.39.69,4,1.2,4.75,1.5v30.38h33.62q7.66,0,10.79,3.14t3.13,9.16a22.3,22.3,0,0,1-1.16,7.3,47.45,47.45,0,0,1-1.85,4.76H799.85v51.71a25.48,25.48,0,0,0,1.62,9.85,15.36,15.36,0,0,0,4.52,6.15,17,17,0,0,0,6.73,3.25,33.77,33.77,0,0,0,8,.92,36.71,36.71,0,0,0,12.52-2.08,35.53,35.53,0,0,0,8.35-3.94l10.67,17.62q-1.86,1.39-5.22,3.48a47.69,47.69,0,0,1-8.23,3.94,73,73,0,0,1-10.9,3.13,64,64,0,0,1-13.22,1.28q-20.41,0-30.84-10.56t-10.44-27.71Z"
          />
          <path
            style={{ fill: "#bcbcbc" }}
            d="M95.8,204.38c-5,4.56-10,9.07-15,13.66-1.13,1.05-2,.52-3,0a40.25,40.25,0,0,0-13.1-4.18c-16.65-2.43-35,7.54-41.19,25.85a38.3,38.3,0,0,0,72.05,25.95c1.16-3.05,1.2-3,4.52-3,5,0,9.91,0,14.87,0a7.6,7.6,0,0,1,3.62.4,57.51,57.51,0,0,1-12.89,27.3,58.4,58.4,0,0,1-34.2,20.26A59.89,59.89,0,0,1,26.59,202C49.76,186.42,79.43,190.46,95.8,204.38Z"
          />
          <path
            style={{ fill: "#bcbcbc" }}
            d="M154.82,192.48c27-.86,53.74,19.08,58.9,49.37a59.83,59.83,0,0,1-48.09,69c-15.58,2.82-30-.21-43.34-8.48a21.66,21.66,0,0,1-2-1.32c-1.57-1.21-1.65-1.51-.23-2.85,2.79-2.64,5.65-5.18,8.49-7.75,1.5-1.36,3.11-2.63,4.51-4.08a2.72,2.72,0,0,1,3.83-.55,36.54,36.54,0,0,0,12.77,4.12,35.73,35.73,0,0,0,25.17-5.48c13.1-8.42,19.28-20.73,17.91-36.17-1.8-20.24-18.81-33.73-36-34.5A38.54,38.54,0,0,0,119,238.11c-1.22,3.07-1.25,3.06-4.58,3.07-5.18,0-10.37,0-15.55,0-2.92,0-3.12-.25-2.4-3.16a60.1,60.1,0,0,1,50.91-45.49A57.37,57.37,0,0,1,154.82,192.48Z"
          />
        </g>
      </g>
    </svg>
  );
}
