import { TFunction } from "i18next";
import { AutoCompleteOptionType, OptionType } from "src/components";
import {
  ApplicableListTypeEnum,
  ApplicableTypeEnum,
  ConditionTypeEnum,
  Coupon,
  CouponErorType,
  DiscountType,
} from "src/types/coupon.model";

export const DEFAULT_COUPON: Coupon = {
  name: "",
  code: "",
  status: "Active",
  locationBase: "Thailand",
  discountCategory: "Product Price",
  discountType: "Fix Amount Discount",
  couponRedeemLimit: {
    isUnlimited: false,
    frequency: "Per Day",
  },
  userRedeemLimit: {
    isUnlimited: false,
    frequency: "Per Day",
  },
  totalRedeem: 0,
  isAbleToDelete: false,
  isAllProductCategories: false,
  productCategories: [],
  description: "",
  conditionType: [ConditionTypeEnum.minimumPurchaseAmount],
  applicableType: ApplicableTypeEnum.All_CATEGORY,
  isAllApplicable: false,
  applicableListType: ApplicableListTypeEnum.applicable,
  applicableList: [],
};

export const DEFAULT_COUPON_ERR = {
  name: "",
  code: "",
  date: "",
  discountAmount: "",
  discountPercentage: "",
  capAt: "",
  category: "",
  miniumPurchaseAmount: "",
  couponLimit: "",
  userLimit: "",
  chainToCoupon: "",
  joinDate: "",
};

export function getDiscountCate(t: TFunction, addAll?: boolean): OptionType[] {
  const type: OptionType[] = [
    {
      title: t("product_price"),
      value: "Product Price",
    },
    {
      title: t("shipping_fee"),
      value: "Shipping Fee",
    },
    {
      title: t("subscription_fee"),
      value: "Subscription Fee",
    },
    {
      title: t("subscription_renewal_fee"),
      value: "Subscription Renewal Fee",
    },
  ];
  addAll &&
    type.unshift({
      title: t("all-categories"),
      value: "All",
    });
  return type;
}

export function getDiscountType(t: TFunction): OptionType[] {
  return [
    {
      title: t("fix-amount-discount"),
      value: "Fix Amount Discount",
    },
    {
      title: t("percent-discount"),
      value: "Percentage Discount",
    },
  ];
}

export function getRedeemType(t: TFunction): OptionType[] {
  return [
    {
      title: t("per_day"),
      value: "Per Day",
    },
    {
      title: t("per_month"),
      value: "Per Month",
    },
    {
      title: t("per_coupon"),
      value: "Per Coupon",
    },
  ];
}

export function getDateError(t: TFunction, startDate?: Date | string, endDate?: Date | string) {
  return !startDate && !endDate ? "required_fields" : "";
}

export function getApplicableListError(
  t: TFunction,
  applicableList: string[],
  applicableType: ApplicableTypeEnum,
) {
  if (
    applicableType === ApplicableTypeEnum.SELECT_CATEGORY ||
    applicableType === ApplicableTypeEnum.SELECT_PLAN ||
    applicableType === ApplicableTypeEnum.SELECT_PRO ||
    applicableType === ApplicableTypeEnum.SELECT_PROMOTION_PRO
  )
    return applicableList.length <= 0 ? "required_fields" : "";
  return "";
}

export function getNameError(t: TFunction, name: string) {
  return !name.trim() ? "required_fields" : "";
}

export function getCodeError(t: TFunction, code: string) {
  return !code.trim() ? "required_fields" : "";
}

export function getDiscountAmountError(t: TFunction, discountType: DiscountType, amount?: number) {
  return discountType === "Fix Amount Discount" && !amount ? "required_fields" : "";
}

export function getDiscountPercentageError(
  t: TFunction,
  discountType: DiscountType,
  percentage?: number,
) {
  return discountType === "Percentage Discount" && !percentage ? "required_fields" : "";
}

export function getCapAtError(t: TFunction, discountType: DiscountType, capAt?: number) {
  return discountType === "Percentage Discount" && !capAt ? "required_fields" : "";
}

export function getMinimunPurError(t: TFunction, value?: number) {
  return !value ? "required_fields" : "";
}

export function getCouponLimitError(t: TFunction, isUnlimited: boolean, limit?: number) {
  return !isUnlimited && !limit ? "required_fields" : "";
}

export function getUserLimitError(t: TFunction, isUnlimited: boolean, limit?: number) {
  return !isUnlimited && !limit ? "required_fields" : "";
}

export function getChainCouponError(t: TFunction, chainToCoupon: string, code: string) {
  if (!chainToCoupon.trim()) return "required_fields";
  if (chainToCoupon.trim() === code.trim())
    return "Chain to coupon code must be different from Coupon code";
  return "";
}

export function getCouponFormErr(t: TFunction, coupon: Coupon) {
  const {
    name,
    code,
    redeemStartDate,
    redeemEndDate,
    discountType,
    discountAmount,
    discountPercentage,
    capAt,
    couponRedeemLimit,
    userRedeemLimit,
    chainToCoupon,
    joinDatePeriodStart,
    joinDatePeriodEnd,
    miniumPurchaseAmount,
    promotePeriodStart,
    promotePeriodEnd,
    joinDateLastRD,
    chainDateLastRD,
    limitPerSponsorMember,
    conditionType,
    applicableList,
    applicableType,
  } = coupon;
  let updateErr: CouponErorType = {
    name: getNameError(t, name || ""),
    code: getCodeError(t, code || ""),
    date: getDateError(t, redeemStartDate, redeemEndDate),
    applicableList: getApplicableListError(t, applicableList, applicableType),
    discountAmount: getDiscountAmountError(t, discountType, discountAmount),
    discountPercentage: getDiscountPercentageError(t, discountType, discountPercentage),
    capAt: getCapAtError(t, discountType, capAt),
    couponLimit: getCouponLimitError(
      t,
      couponRedeemLimit.isUnlimited,
      couponRedeemLimit.limitAmount,
    ),
    userLimit: getUserLimitError(t, userRedeemLimit.isUnlimited, userRedeemLimit.limitAmount),
  };
  if (conditionType.includes(ConditionTypeEnum.chainToCoupon))
    updateErr = { ...updateErr, chainCoupon: getChainCouponError(t, chainToCoupon || "", code) };
  if (conditionType.includes(ConditionTypeEnum.joinDate))
    updateErr = {
      ...updateErr,
      joinDate: getDateError(t, joinDatePeriodStart, joinDatePeriodEnd),
    };
  if (conditionType.includes(ConditionTypeEnum.minimumPurchaseAmount))
    updateErr = {
      ...updateErr,
      miniumPurchaseAmount: getMinimunPurError(t, miniumPurchaseAmount),
    };
  if (conditionType.includes(ConditionTypeEnum.promotePeriod))
    updateErr = {
      ...updateErr,
      promoteDate: getDateError(t, promotePeriodStart, promotePeriodEnd),
    };
  if (conditionType.includes(ConditionTypeEnum.joinDateLastRD))
    updateErr = {
      ...updateErr,
      lastDayFromJoinDate: getMinimunPurError(t, joinDateLastRD),
    };
  if (conditionType.includes(ConditionTypeEnum.chainDateLastRD))
    updateErr = {
      ...updateErr,
      lastDayFromChainCoupon: getMinimunPurError(t, chainDateLastRD),
    };
  if (conditionType.includes(ConditionTypeEnum.limitSponsor))
    updateErr = {
      ...updateErr,
      limitPerSponsorMember: getMinimunPurError(t, limitPerSponsorMember),
    };

  return updateErr;
}

export const getApplicableListType = (t: TFunction) => {
  return [
    {
      title: t(ApplicableListTypeEnum.applicableTitle),
      value: ApplicableListTypeEnum.applicable,
    },
    {
      title: t(ApplicableListTypeEnum.exemptTitle),
      value: ApplicableListTypeEnum.exempt,
    },
  ];
};
