export const FETCH_COUPON_SUGGESTTION = "FETCH_COUPON_SUGGESTTION";
export const FETCH_MEMBERTIER = "FETCH_MEMBERTIER";

export type FetchCouponSuggesttion = {
  type: string;
  payload: {
    keyword: string;
    locationBase: string;
    couponCode: string;
  };
};

export type payloadCouponSuggestions = {
  keyword: string;
  locationBase: string;
  couponCode: string;
};
