import { call, put } from "redux-saga/effects";
import { config } from "src/constants/config";
import { authorizedRequest } from "src/lib/request";
import { List } from "src/types/common.modal";
import { Coupon, MemberTierType } from "src/types/coupon.model";
import {
  FETCH_COUPON_SUGGESTTION,
  FETCH_MEMBERTIER,
  FetchCouponSuggesttion,
  payloadCouponSuggestions,
} from "./option-dropdown.type";
import { setCouponSuggestions, setMemberTier } from "./options-dropdown.slice";
// get list MEMBER TIER
export type FetchMemberTier = {
  type: string;
};

const getMemberTier = async () => {
  const response = (await authorizedRequest.get(
    `${config.apiBaseUrl}/sponsors/tiers`,
  )) as MemberTierType;

  return response;
};

export function* watcherMemberTier() {
  try {
    const response: MemberTierType[] = yield call(() => getMemberTier());
    yield put(
      setMemberTier(
        response.map((item) => {
          return { value: item.tier, title: item.desc };
        }),
      ),
    );
  } catch (error) {}
}
export const getMemberTierAction = () => ({
  type: FETCH_MEMBERTIER,
});

// get list coupon suggestion for chain to coupon

const getCouponSuggestions = async (params: payloadCouponSuggestions) => {
  const response = await authorizedRequest.get<List<Coupon>>(
    `${config.apiBaseUrl}/admin/coupons/coupon-chains`,
    {
      params,
    },
  );
  return response.data;
};

export function* watcherCouponSuggestions({ payload }: FetchCouponSuggesttion) {
  try {
    const response: { _id: string; code: string }[] = yield call(() =>
      getCouponSuggestions(payload),
    );
    yield put(
      setCouponSuggestions(
        response.map((item) => {
          return item.code;
        }),
      ),
    );
  } catch (error) {}
}

export const getCouponSuggestionsAction = (payload: {
  keyword?: string;
  locationBase?: string;
  couponCode?: string;
}) => ({
  type: FETCH_COUPON_SUGGESTTION,
  payload,
});
