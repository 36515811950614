import { SvgIconConstituentValues } from "./icons.type";
// width="48.262"
//       height="20.615"
//       viewBox="0 0 48.262 20.615"
export default function LogoSidebar(props: SvgIconConstituentValues) {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="48.262px"
      height="20.615px"
      viewBox="0 0 852.3 343.9"
      className="enableBackground"
      xmlSpace="preserve"
      {...props}
    >
      <g id="Layer_2_00000024715397865646631820000003910285197217243560_">
        <g id="Layer_1-2">
          <g className="enableBackground">
            <path
              style={{ fill: "#fff" }}
              d="M22.8,149.3c0.9,0.8,3,2,6.2,3.6c3.2,1.6,7.3,3.2,12.2,4.8c4.9,1.6,10.6,3.1,17.1,4.4c6.5,1.3,13.5,2,21,2
				c6.2,0,11.1-0.4,14.8-1.3c3.7-0.8,6.5-2,8.5-3.3c2-1.4,3.3-3,3.9-4.7c0.6-1.8,0.9-3.6,0.9-5.4c0-3.7-1.5-6.7-4.5-9.1
				c-3-2.4-8.6-4.3-16.7-5.7l-23.5-4.2c-6.2-1.1-12.3-2.6-18.5-4.6c-6.2-2-11.7-4.8-16.5-8.3c-4.8-3.5-8.8-8.1-11.9-13.6
				c-3.1-5.5-4.6-12.4-4.6-20.5c0-7.1,1.3-13.7,3.9-20c2.6-6.2,6.6-11.7,12-16.3c5.4-4.6,12.2-8.3,20.4-11c8.2-2.7,17.9-4,29-4
				c8,0,15,0.4,21,1.3c6,0.8,11.2,1.9,15.5,3.1c4.3,1.2,7.8,2.5,10.5,3.7c2.7,1.2,4.7,2.2,5.9,3c2.6,1.5,5.1,3.7,7.5,6.3
				c2.4,2.7,3.6,5.9,3.6,9.6c0,2.6-0.5,5.1-1.4,7.5c-0.9,2.4-2,4.6-3.3,6.6c-1.3,2-2.7,3.7-4.3,5.2c-1.5,1.5-2.8,2.6-3.9,3.3
				c-1.5-1.5-3.8-3.3-6.9-5.2c-3.1-1.9-6.8-3.7-11.1-5.2c-4.3-1.5-9.2-2.8-14.8-3.9c-5.5-1.1-11.5-1.6-17.8-1.6
				c-6,0-10.8,0.5-14.3,1.5c-3.5,1-6.2,2.2-8,3.7c-1.8,1.5-2.9,3.1-3.5,5c-0.5,1.8-0.8,3.5-0.8,5.1c0,1.4,0.2,2.7,0.6,3.9
				c0.4,1.2,1.3,2.4,2.8,3.6c1.5,1.2,3.5,2.2,6.2,3.2c2.7,1,6.3,2,11,2.9l24.5,4.8c8.6,1.7,16.2,3.8,22.7,6.3
				c6.5,2.5,12,5.7,16.5,9.3c4.5,3.7,7.8,8.2,10,13.4c2.2,5.2,3.3,11.5,3.3,18.7c0,16.2-6,28.8-18.1,37.8
				c-12.1,9.1-29.1,13.6-51.1,13.6c-10,0-19.2-0.8-27.6-2.5c-8.4-1.7-15.7-3.7-22-5.9c-6.3-2.2-11.4-4.3-15.3-6.3
				c-3.9-2-6.3-3.4-7-4.2L22.8,149.3z"
            />
            <path
              style={{ fill: "#fff" }}
              d="M309.6,182.7c-1.7,1.2-4.1,2.7-7.3,4.5c-3.2,1.8-7.1,3.5-11.9,5.2c-4.8,1.7-10.4,3.2-16.8,4.4
				c-6.5,1.2-13.8,1.8-21.9,1.8c-11.5,0-22.5-1.7-32.8-5.2c-10.3-3.5-19.3-8.7-27.1-15.6c-7.8-6.9-13.9-15.5-18.5-25.8
				c-4.5-10.3-6.8-22.3-6.8-36c0-13.4,2.2-25.3,6.6-35.6c4.4-10.4,10.4-19.2,18-26.3c7.6-7.2,16.5-12.6,26.8-16.4
				c10.2-3.8,21.3-5.7,33.1-5.7c6.3,0,12,0.4,17.1,1.2c5.1,0.8,9.6,1.8,13.5,3c3.9,1.2,7.3,2.5,10,3.9c2.8,1.4,5,2.6,6.7,3.7
				c3.5,2.6,6.1,5.3,7.6,8.2c1.5,2.8,2.3,5.8,2.3,8.9c0,2.9-0.6,5.7-1.7,8.3c-1.2,2.6-2.5,5-4.2,7c-1.6,2.1-3.2,3.8-4.8,5.3
				c-1.6,1.5-3,2.5-4,3.1c-1.5-1.5-3.5-3.3-5.9-5.4c-2.4-2.1-5.3-4-8.7-5.7c-3.4-1.7-7.3-3.2-11.7-4.4c-4.4-1.2-9.3-1.8-14.7-1.8
				c-6.8,0-12.9,1.1-18.5,3.3c-5.5,2.2-10.3,5.5-14.4,9.7c-4.1,4.2-7.2,9.3-9.5,15.3c-2.2,6-3.3,12.8-3.3,20.3
				c0,7.7,1.2,14.5,3.7,20.3c2.5,5.8,5.8,10.7,10.2,14.7c4.3,3.9,9.4,6.9,15.3,8.9c5.9,2,12.3,3,19.3,3c8.6,0,16-1.1,22.3-3.2
				c6.2-2.2,11-4.4,14.2-6.7l17.3,29.8H309.6z"
            />
            <path
              style={{ fill: "#fff" }}
              d="M335,56.1c0.2-3.8,0.9-7.1,2.2-9.7c1.3-2.6,3-4.7,5.1-6.1c2.1-1.5,4.4-2.5,7-3.1c2.6-0.6,5.4-0.9,8.3-0.9
				c6,0,11.1,0.8,15.2,2.5c4.2,1.7,6.9,3.2,8.3,4.4c5.5,11.4,11.3,23.5,17.4,36.3c6.1,12.8,12.3,25.3,18.6,37.3
				c5.5-11.1,11-22,16.3-32.8c5.3-10.8,10.2-21,14.7-30.7c2.9-6.6,6.5-11.1,10.7-13.5c4.2-2.4,9-3.6,14.4-3.6c5.8,0,11,0.8,15.3,2.4
				s7.2,2.9,8.4,3.8l8.8,152.1H467l-5.3-94.6c-2.8,5.7-5.6,11.5-8.5,17.5c-2.9,6-5.7,11.9-8.4,17.8c-2.7,5.8-5.2,11.4-7.6,16.7
				c-2.4,5.3-4.5,10-6.3,14.2h-28.6c-1.7-4.2-3.8-9-6.3-14.5c-2.5-5.5-5.2-11.3-8-17.3c-2.8-6-5.6-12-8.5-17.9
				c-2.9-5.9-5.6-11.3-8.1-16.3l-5.1,94.4h-39L335,56.1z"
            />
          </g>
          <path
            style={{ fill: "#5A595A" }}
            d="M238.7,274.1c-0.1-7.1,1.3-14.1,4.3-20.6c2.7-5.9,6.7-11.2,11.7-15.4c5.2-4.3,11.2-7.6,17.7-9.7
			c7.3-2.3,14.8-3.4,22.4-3.3c7.6-0.1,15.2,1.1,22.5,3.3c6.5,2,12.6,5.3,17.8,9.7c5,4.2,8.9,9.5,11.6,15.4
			c2.8,6.5,4.3,13.5,4.1,20.6v69.7h-26.2v-63c0-10.4-2.5-18.4-7.6-23.8s-12.5-8.2-22.3-8.2c-10,0-17.4,2.7-22.4,8.2
			c-5,5.4-7.5,13.4-7.5,23.8v63h-26.2V274.1z"
          />
          <path
            style={{ fill: "#5A595A" }}
            d="M377.6,274.1c-0.1-7.1,1.3-14.1,4.2-20.6c2.7-5.9,6.7-11.2,11.7-15.4c5.2-4.3,11.2-7.6,17.7-9.7
			c7.3-2.3,14.8-3.4,22.4-3.3c7.6-0.1,15.2,1.1,22.5,3.3c6.5,2,12.5,5.3,17.8,9.7c5,4.2,8.9,9.5,11.6,15.4
			c2.8,6.5,4.3,13.5,4.1,20.6v69.7h-26.2v-63c0-10.4-2.5-18.4-7.6-23.8s-12.5-8.2-22.3-8.2c-10,0-17.4,2.7-22.4,8.2
			c-5,5.4-7.5,13.4-7.5,23.8v63h-26.2V274.1z"
          />
          <path
            style={{ fill: "#5A595A" }}
            d="M617.9,332.4c-1.2,0.8-3,1.8-5.6,3.1c-3.1,1.5-6.2,2.8-9.5,3.8c-4.3,1.3-8.7,2.4-13.2,3.1
			c-5.4,0.9-10.9,1.4-16.5,1.3c-8,0.1-15.9-1.2-23.4-3.8c-7-2.4-13.5-6.3-19-11.3c-5.5-5.1-9.9-11.4-12.9-18.4
			c-3.3-7.9-4.9-16.4-4.7-25c-0.1-8.4,1.5-16.7,4.6-24.5c2.9-7.1,7.2-13.6,12.8-19c5.5-5.4,12.1-9.5,19.2-12.3
			c7.7-3,16-4.5,24.3-4.4c7-0.1,13.9,0.9,20.6,3c5.5,1.8,10.6,4.5,15.1,8.1c7.9,6.3,12.5,15.9,12.4,26.1c0.1,4.9-0.9,9.7-2.9,14.2
			c-1.9,4.3-5.4,7.9-10.3,11c-4.9,3.1-11.6,5.5-19.9,7.2c-8.4,1.7-19,2.6-31.8,2.6c-3,0-5.8,0-8.4-0.1s-5.4-0.2-8.4-0.3
			c1.1,7.4,5.1,14,11.1,18.5c6.2,4.7,15,7,26.5,7c3.6,0,7.2-0.3,10.7-0.9c3-0.5,6-1.3,8.8-2.2c2.3-0.8,4.5-1.7,6.7-2.7l4.2-2
			L617.9,332.4z M539.3,279.3c3.4,0.2,6.7,0.2,9.8,0.2h9.4c6.7,0.1,13.3-0.4,19.9-1.6c4.1-0.7,8.1-2.1,11.9-4
			c2.8-1.6,4.7-3.5,5.6-5.6c0.9-2,1.3-4.3,1.3-6.5c0-5.7-2.3-9.9-6.8-12.8c-4.6-2.8-10.2-4.2-16.9-4.2c-5-0.1-10,0.9-14.5,2.9
			c-4,1.8-7.6,4.4-10.5,7.7c-2.9,3.3-5.1,7-6.6,11.1C540.3,270.7,539.5,275,539.3,279.3z"
          />
          <path
            style={{ fill: "#5A595A" }}
            d="M627.2,343.8c0.8-1.1,2.7-3.5,5.7-7.2c3-3.7,6.7-8.2,11-13.6c4.4-5.3,9.2-11.2,14.5-17.6s10.7-12.8,16.3-19.4
			c-7.4-8.1-13.8-14.7-19.1-20.1s-9.6-9.8-12.9-13.4c-2.8-2.9-5.2-6.1-7.4-9.5c-1.5-2.4-2.3-5.2-2.4-8.1c0-2.7,1.1-5.3,3.1-7.2
			c2.1-2.1,5.5-3.2,10.2-3.2c2.6,0,5.1,0.3,7.6,1.1c1.5,0.4,2.9,0.9,4.3,1.5c0.6,0.8,2.1,2.5,4.4,5.1s5.1,5.7,8.3,9.4
			s6.7,7.6,10.3,11.9s7.3,8.4,10.9,12.3c3-3.8,6-7.5,9.1-11.2c3.1-3.6,5.9-7,8.4-10.1c2.5-3.1,4.7-5.7,6.5-7.8
			c1.8-2.1,3-3.5,3.5-4.2c2.2-2.4,4.7-4.5,7.6-6.1c2.8-1.4,5.9-2.1,9-2c4-0.1,7.9,0.8,11.4,2.7c3.2,1.8,5.2,3.3,5.9,4.4
			c-1.3,1.4-3.4,3.8-6.3,7.2c-2.9,3.4-6.3,7.4-10.3,12.1l-13.2,15.5c-4.8,5.7-9.8,11.6-14.8,17.6c5.7,6.7,11.3,13.2,16.9,19.5
			s10.6,12.2,15.3,17.6c4.7,5.4,8.7,10.1,12.1,14c3.4,3.9,5.7,6.8,7,8.5h-33.5c-3.6-4.3-8.4-9.8-14.1-16.5s-12.7-14.6-20.8-23.6
			c-7.1,8.5-13.7,16.4-19.6,23.6c-5.9,7.2-10.3,12.7-13.2,16.5L627.2,343.8z"
          />
          <path
            style={{ fill: "#5A595A" }}
            d="M773.4,203.5c0-4.3,1.2-7.5,3.7-9.4c2.5-1.9,5.9-2.9,10.2-2.9c2.6,0,5.2,0.3,7.8,1c2.4,0.7,4,1.2,4.8,1.5
			v30.4h33.6c5.1,0,8.7,1,10.8,3.1c2.1,2.1,3.1,5.1,3.1,9.2c0,2.5-0.4,4.9-1.2,7.3c-0.5,1.6-1.1,3.2-1.8,4.8h-44.5v51.8
			c-0.1,3.4,0.4,6.7,1.6,9.9c1,2.4,2.5,4.5,4.5,6.1c2,1.6,4.3,2.7,6.7,3.2c2.6,0.6,5.3,0.9,8,0.9c4.3,0,8.5-0.7,12.5-2.1
			c2.9-0.9,5.8-2.3,8.3-3.9l10.7,17.6c-1.2,0.9-3,2.1-5.2,3.5c-2.6,1.6-5.4,2.9-8.2,3.9c-3.5,1.3-7.2,2.4-10.9,3.1
			c-4.4,0.9-8.8,1.3-13.2,1.3c-13.6,0-23.9-3.5-30.8-10.5s-10.4-16.3-10.4-27.7L773.4,203.5z"
          />
          <path
            style={{ fill: "#fff" }}
            d="M95.8,236.4c-5,4.6-10,9.1-15,13.7c-1.1,1-2,0.5-3,0c-4.1-2.1-8.5-3.5-13.1-4.2c-16.7-2.4-35,7.5-41.2,25.9
			c-7.8,23.1,8.2,47.8,32.4,50.2c17.2,1.7,33.4-8.2,39.7-24.3c1.2-3,1.2-3,4.5-3c5,0,9.9,0,14.9,0c1.2-0.2,2.5,0,3.6,0.4
			c-1.8,10.1-6.2,19.5-12.9,27.3C97,333,84.9,340.1,71.5,342.7c-32.5,6.4-64-14.7-70.3-47.2c-4.7-23.7,5.4-47.9,25.4-61.3
			C49.8,218.5,79.4,222.5,95.8,236.4z"
          />
          <path
            style={{ fill: "#5A595A" }}
            d="M154.8,224.6c27-0.9,53.7,19.1,58.9,49.4c5.6,32.3-15.9,63-48.1,69c-15.6,2.8-30-0.2-43.3-8.5
			c-0.7-0.4-1.4-0.8-2-1.3c-1.6-1.2-1.7-1.5-0.2-2.9c2.8-2.6,5.7-5.2,8.5-7.8c1.5-1.4,3.1-2.6,4.5-4.1c0.9-1.2,2.6-1.5,3.8-0.6
			c0,0,0,0,0,0c4,2.1,8.3,3.5,12.8,4.1c8.8,1.4,17.8-0.6,25.2-5.5c13.1-8.4,19.3-20.7,17.9-36.2c-1.8-20.2-18.8-33.7-36-34.5
			c-16.5-0.8-31.7,8.9-37.8,24.3c-1.2,3.1-1.2,3.1-4.6,3.1c-5.2,0-10.4,0-15.6,0c-2.9,0-3.1-0.2-2.4-3.2
			c5.9-24.3,26.1-42.4,50.9-45.5C149.9,224.4,152.3,224.4,154.8,224.6z"
          />
        </g>
      </g>
    </svg>
  );
}
