export const pageSizeOptions = [
  {
    title: "5",
    value: 5,
  },
  {
    title: "10",
    value: 10,
  },
  {
    title: "35",
    value: 35,
  },
  {
    title: "50",
    value: 50,
  },
  {
    title: "100",
    value: 100,
  },
];
