import { AutoCompleteOptionType } from "src/components";
import { CategoryModel } from "./category.model";
import { SearchParams, StatusElement } from "./common.modal";
import { ProductModel } from "./inventory-management.model";

export type DiscountCategory =
  | "Product Price"
  | "Shipping Fee"
  | "Subscription Fee"
  | "Subscription Renewal Fee";
export type DiscountType = "Fix Amount Discount" | "Percentage Discount";
export type FrequencyType = "Per Month" | "Per Day" | "Per Coupon";

export type RedeemLimit = {
  _id?: string;
  isUnlimited: boolean;
  limitAmount?: number;
  frequency: FrequencyType;
};

export interface Coupon {
  _id?: string;
  name: string;
  code: string;
  locationBase: string;
  status: StatusElement;
  redeemStartDate?: string;
  redeemEndDate?: string;
  discountCategory: DiscountCategory;
  discountType: DiscountType;
  discountAmount?: number;
  discountPercentage?: number;
  capAt?: number;
  couponRedeemLimit: RedeemLimit;
  userRedeemLimit: RedeemLimit;
  totalRedeem: number;
  isAbleToDelete: boolean;
  isAllProductCategories: boolean;
  productCategories: CategoryModel[] | string[] | AutoCompleteOptionType[] | null;
  description: string;
  miniumPurchaseAmount?: number;
  chainToCoupon?: string;
  joinDatePeriodStart?: string;
  joinDatePeriodEnd?: string;
  memberTier?: string;
  memberType?: string;
  orderType?: string;
  promotePeriodStart?: string;
  promotePeriodEnd?: string;
  joinDateLastRD?: number;
  chainDateLastRD?: number;
  conditionType: string[];
  limitPerSponsorMember?: number;
  applicableType: ApplicableTypeEnum;
  applicableList: string[];
  isAllApplicable: boolean;
  applicableListType: ApplicableListTypeEnum;
  detailApplicableList?: AutoCompleteOptionType[] | ProductModel[];
}

export type keyConditions =
  | "miniumPurchaseAmount"
  | "chainToCoupon"
  | "joinDatePeriodStart"
  | "joinDatePeriodEnd"
  | "memberTier"
  | "memberType"
  | "orderType"
  | "promotePeriodStart"
  | "promotePeriodEnd"
  | "joinDateLastRD"
  | "chainDateLastRD"
  | "limitPerSponsorMember";

export interface CouponHistoryData {
  orderNumber: string;
  memberId: string;
  usedAt: string;
  totalPrice: number;
  couponRedeemAmount: number;
}
export interface CouponHistory {
  coupon: {
    _id: string;
    name: string;
    code: string;
    locationBase?: string;
  };
  orders: {
    total: number;
    page: number;
    limit: number;
    data: CouponHistoryData[];
  };
}

export type CouponErorType = {
  name?: string;
  code?: string;
  date?: string;
  discountAmount?: string;
  discountPercentage?: string;
  capAt?: string;
  applicableList?: string;
  miniumPurchaseAmount?: string;
  couponLimit?: string;
  userLimit?: string;
  joinDate?: string;
  chainCoupon?: string;
  promoteDate?: string;
  lastDayFromJoinDate?: string;
  lastDayFromChainCoupon?: string;
  limitPerSponsorMember?: string;
};
export type keyCouponError =
  | "joinDate"
  | "chainCoupon"
  | "miniumPurchaseAmount"
  | "promoteDate"
  | "lastDayFromJoinDate"
  | "lastDayFromChainCoupon"
  | "limitPerSponsorMember";

export type CouponChildProps = {
  coupon: Coupon;
  onChangeCoupon: (coupon: Coupon) => void;
  couponErr: CouponErorType;
  onChangeError: (errors: CouponErorType) => void;
  type?: "Create" | "Edit";
};

export type CouponParams = SearchParams & {
  _id?: string;
  category?: string;
  locationBase?: string;
  discountCategory?: DiscountCategory;
  redeemStartDate?: string;
  redeemEndDate?: string;
  redeemMinAmount?: number;
  redeemMaxAmount?: number;
};

export enum ConditionTypeEnum {
  joinDate = "Join Date Period",
  chainToCoupon = "Chain to Coupon",
  minimumPurchaseAmount = "Minimum Purchase Amount",
  orderType = "Order Type",
  memberTier = "Member Tier",
  promotePeriod = "Promote Period",
  memberType = "Member Type",
  joinDateLastRD = "Last Redeemable Day from Join Date",
  chainDateLastRD = "Last Redeemable Day from Chain Coupon Redeem Date",
  limitSponsor = "Limit Per Sponsor Member",
}

export type MemberTierType = { tier: string; desc: string };

export enum ApplicableTypeEnum {
  All_CATEGORY = "All Category",
  SELECT_CATEGORY = "Select Category",
  ALL_PRODUCTS = "All Products",
  ALL_PROMOTION_PRO = "All promotion products",
  SELECT_PROMOTION_PRO = "Select promotion products",
  SELECT_PRO = "Select product",
  ALL_PLAN = "All plan",
  SELECT_PLAN = "Select plan",
}

export enum ApplicableListTypeEnum {
  applicable = "Applicable List",
  exempt = "Exempt List",
  applicableTitle = "applicable_list",
  exemptTitle = "exempt_list",
}
