import { SvgIconConstituentValues } from "./icons.type";

export default function NoDataIcon(props: SvgIconConstituentValues) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="186.78"
      height="130.597"
      viewBox="0 0 186.78 130.597"
      {...props}
    >
      <g id="no_data_icon" transform="translate(0)">
        <path
          id="Path_3838"
          data-name="Path 3838"
          d="M450.555,61.067,447.439,76.16l-2.006-1.954-3.413,2.433L439.41,73.4l-5.853,4.254-5-8.361-5.576,3.267-2.211-3.052-7.081,3.99-2.133-4.56,4.964-24.04,26.613,5.494Z"
          transform="translate(-277.517 -30.276)"
          fill="#ebebeb"
        />
        <path
          id="Path_3839"
          data-name="Path 3839"
          d="M504.761,61.77l7.419,10.673-9.23-1.905Z"
          transform="translate(-339.142 -41.652)"
          fill="#dbdbdb"
        />
        <path
          id="Path_3840"
          data-name="Path 3840"
          d="M436.012,135.194l-4.573,22.144-35.839-7.4,4.537-21.971,2.13,4.563,7.081-3.99,2.211,3.049,5.579-3.267,5,8.361,5.849-4.254,2.609,3.237,3.413-2.433Z"
          transform="translate(-266.755 -86.291)"
          fill="#ebebeb"
        />
        <path
          id="Path_3841"
          data-name="Path 3841"
          d="M280.539,46.307l-36.527,2.26-2.9-46.89L268.231,0l9.96,8.351Z"
          transform="translate(-162.582)"
          fill="#ebebeb"
        />
        <path
          id="Path_3842"
          data-name="Path 3842"
          d="M324.38,0l9.96,8.351-9.406.583Z"
          transform="translate(-218.731)"
          fill="#dbdbdb"
        />
        <path
          id="Path_3843"
          data-name="Path 3843"
          d="M365.656,104.318l-.14-1.055a9.4,9.4,0,0,1,2.137-7.211c1.716-2.28,2.648-3.908,2.547-5.755-.117-2.084-1.5-3.4-4.078-3.3a7.257,7.257,0,0,0-4.055,1.5l-1.127-2.521a10.692,10.692,0,0,1,5.752-1.954c4.7-.264,6.983,2.518,7.165,5.621.153,2.778-1.286,4.885-3.117,7.305-1.677,2.224-2.231,4.061-1.99,6.136l.1,1.062Zm-.576,5.771a2.421,2.421,0,0,1,2.306-2.713,2.561,2.561,0,1,1-2.306,2.713Z"
          transform="translate(-270.133 -72.771)"
          fill="#dbdbdb"
        />
        <path
          id="Path_3844"
          data-name="Path 3844"
          d="M112.287,144.138l-3.677-1.668L108.1,143.6a.977.977,0,0,1-.84.557,4.148,4.148,0,0,0-.6.059.977.977,0,0,1-.928-.384l-.72-1-3.283,2.352.72,1a.977.977,0,0,1,.065,1,5.566,5.566,0,0,0-.248.55.977.977,0,0,1-.8.612l-1.231.121.4,4.016,1.234-.121a.958.958,0,0,1,.9.449l.169.244c.055.081.117.16.179.238a.977.977,0,0,1,.137.993l-.515,1.133,3.674,1.668.515-1.127a.977.977,0,0,1,.837-.56,5.328,5.328,0,0,0,.6-.055.954.954,0,0,1,.928.384l.72,1,3.28-2.365-.72-.977a.977.977,0,0,1-.065-1,5.864,5.864,0,0,0,.248-.547.977.977,0,0,1,.8-.616l1.231-.121-.394-4.019-1.234.124a.977.977,0,0,1-.9-.449c-.052-.085-.107-.166-.166-.248s-.117-.16-.179-.238a.958.958,0,0,1-.137-1Zm-2.088,3.716a3.3,3.3,0,1,1-2.147-1.339,3.306,3.306,0,0,1,2.147,1.339Z"
          transform="translate(-94.342 -112.193)"
          fill="#ebebeb"
        />
        <path
          id="Path_3845"
          data-name="Path 3845"
          d="M617.106,287l-2.449,1.414.433.749a.674.674,0,0,1-.023.7,3.172,3.172,0,0,0-.212.368.677.677,0,0,1-.6.371h-.866v2.827h.866a.677.677,0,0,1,.6.371,3.177,3.177,0,0,0,.212.368.674.674,0,0,1,.023.7l-.433.749,2.449,1.414.433-.752a.671.671,0,0,1,.625-.326h.414a.671.671,0,0,1,.622.326l.436.756,2.449-1.414-.433-.752a.674.674,0,0,1,.023-.7,3.122,3.122,0,0,0,.212-.365.677.677,0,0,1,.6-.371h.873v-2.827H622.5a.677.677,0,0,1-.6-.371,3.119,3.119,0,0,0-.212-.365.681.681,0,0,1-.023-.707l.433-.749L619.637,287l-.433.752a.671.671,0,0,1-.622.326h-.417a.671.671,0,0,1-.625-.326Zm1.264,2.7a.964.964,0,1,1,0-.007Z"
          transform="translate(-440.361 -209.651)"
          fill="#ebebeb"
        />
        <path
          id="Path_3846"
          data-name="Path 3846"
          d="M182.828,86.339l-2.244,1.726.524.687a.671.671,0,0,1,.068.7,3.139,3.139,0,0,0-.163.388.668.668,0,0,1-.544.446l-.86.114.365,2.8.86-.114a.674.674,0,0,1,.651.293,2.9,2.9,0,0,0,.257.326.651.651,0,0,1,.114.7l-.326.8,2.606,1.085.326-.8a.674.674,0,0,1,.576-.407l.208-.023a1.12,1.12,0,0,0,.205-.033.677.677,0,0,1,.651.244l.531.694,2.244-1.716-.528-.69a.677.677,0,0,1-.065-.7,3.729,3.729,0,0,0,.163-.388.674.674,0,0,1,.544-.446l.86-.111-.365-2.8-.86.111a.674.674,0,0,1-.651-.29,3.629,3.629,0,0,0-.254-.326.651.651,0,0,1-.117-.694l.326-.8-2.606-1.088-.326.8a.651.651,0,0,1-.573.407l-.208.023a1.12,1.12,0,0,0-.205.033.674.674,0,0,1-.651-.248Zm1.606,2.518a1.025,1.025,0,1,0,0,.01Z"
          transform="translate(-147.861 -74.129)"
          fill="#ebebeb"
        />
        <ellipse
          id="Ellipse_227"
          data-name="Ellipse 227"
          cx="93.39"
          cy="10.233"
          rx="93.39"
          ry="10.233"
          transform="translate(0 110.131)"
          fill="#ebebeb"
        />
        <path
          id="Path_3847"
          data-name="Path 3847"
          d="M235.209,207.845H135.98l14.347-81.375H249.56Z"
          transform="translate(-91.692 -85.279)"
          fill="#d85903"
        />
        <path
          id="Path_3848"
          data-name="Path 3848"
          d="M213.993,100.59H181.515l-1.485,8.429h34.755Z"
          transform="translate(-121.395 -67.828)"
          fill="#d85903"
        />
        <g
          id="Group_17111"
          data-name="Group 17111"
          transform="translate(44.288 32.762)"
          opacity="0.1"
        >
          <path
            id="Path_3849"
            data-name="Path 3849"
            d="M235.209,207.845H135.98l14.347-81.375H249.56Z"
            transform="translate(-135.98 -118.041)"
            fill="#d85903"
          />
          <path
            id="Path_3850"
            data-name="Path 3850"
            d="M213.993,100.59H181.515l-1.485,8.429h34.755Z"
            transform="translate(-165.683 -100.59)"
            fill="#d85903"
          />
        </g>
        <path
          id="Path_3851"
          data-name="Path 3851"
          d="M255.663,154.787a6.26,6.26,0,0,1,0-2.837,5.4,5.4,0,0,1,.163,1.42A5.536,5.536,0,0,1,255.663,154.787Z"
          transform="translate(-199.034 -118.586)"
          fill="#263238"
        />
        <path
          id="Path_3852"
          data-name="Path 3852"
          d="M244.167,158.149a5.537,5.537,0,0,1-.85-1.146,5.473,5.473,0,0,1-.567-1.3,6.23,6.23,0,0,1,1.417,2.456Z"
          transform="translate(-190.437 -121.114)"
          fill="#263238"
        />
        <path
          id="Path_3853"
          data-name="Path 3853"
          d="M235.639,166.937a5.727,5.727,0,0,1-1.3-.567,5.537,5.537,0,0,1-1.146-.85,5.471,5.471,0,0,1,1.3.567A5.536,5.536,0,0,1,235.639,166.937Z"
          transform="translate(-183.991 -127.736)"
          fill="#263238"
        />
        <path
          id="Path_3854"
          data-name="Path 3854"
          d="M232.634,178.483a6.244,6.244,0,0,1-2.834,0A6.243,6.243,0,0,1,232.634,178.483Z"
          transform="translate(-181.705 -136.367)"
          fill="#263238"
        />
        <path
          id="Path_3855"
          data-name="Path 3855"
          d="M235.989,187.68a5.537,5.537,0,0,1-1.146.85,5.472,5.472,0,0,1-1.3.567,5.518,5.518,0,0,1,1.146-.847A5.735,5.735,0,0,1,235.989,187.68Z"
          transform="translate(-184.227 -142.679)"
          fill="#263238"
        />
        <path
          id="Path_3856"
          data-name="Path 3856"
          d="M244.777,194.08a6.247,6.247,0,0,1-1.417,2.452,6.188,6.188,0,0,1,1.417-2.452Z"
          transform="translate(-190.848 -146.994)"
          fill="#263238"
        />
        <path
          id="Path_3857"
          data-name="Path 3857"
          d="M256.323,196.29a6.243,6.243,0,0,1,0,2.834A6.244,6.244,0,0,1,256.323,196.29Z"
          transform="translate(-199.479 -148.484)"
          fill="#263238"
        />
        <path
          id="Path_3858"
          data-name="Path 3858"
          d="M265.53,193.72a5.728,5.728,0,0,1,.847,1.146,5.6,5.6,0,0,1,.57,1.3,5.538,5.538,0,0,1-.85-1.146A5.471,5.471,0,0,1,265.53,193.72Z"
          transform="translate(-205.798 -146.752)"
          fill="#263238"
        />
        <path
          id="Path_3859"
          data-name="Path 3859"
          d="M271.92,187.08a5.59,5.59,0,0,1,1.3.567,5.515,5.515,0,0,1,1.143.85,5.515,5.515,0,0,1-1.3-.57A5.639,5.639,0,0,1,271.92,187.08Z"
          transform="translate(-210.106 -142.274)"
          fill="#263238"
        />
        <path
          id="Path_3860"
          data-name="Path 3860"
          d="M274.13,177.782a5.684,5.684,0,0,1,1.417-.163,5.536,5.536,0,0,1,1.417.163,5.4,5.4,0,0,1-1.417.163A5.683,5.683,0,0,1,274.13,177.782Z"
          transform="translate(-211.597 -135.894)"
          fill="#263238"
        />
        <path
          id="Path_3861"
          data-name="Path 3861"
          d="M271.57,166.327a6.188,6.188,0,0,1,2.452-1.417A6.247,6.247,0,0,1,271.57,166.327Z"
          transform="translate(-209.87 -127.325)"
          fill="#263238"
        />
        <path
          id="Path_3862"
          data-name="Path 3862"
          d="M264.92,157.8a5.47,5.47,0,0,1,.567-1.3,5.538,5.538,0,0,1,.85-1.146,5.472,5.472,0,0,1-.567,1.3A5.656,5.656,0,0,1,264.92,157.8Z"
          transform="translate(-205.386 -120.878)"
          fill="#263238"
        />
        <path
          id="Path_3863"
          data-name="Path 3863"
          d="M205.087,213.961H105.639L91.52,135.54h99.448Z"
          transform="translate(-61.712 -91.395)"
          fill="#ff7500"
        />
        <circle
          id="Ellipse_228"
          data-name="Ellipse 228"
          cx="4.133"
          cy="4.133"
          r="4.133"
          transform="translate(60.136 83.818)"
          fill="#263238"
        />
        <circle
          id="Ellipse_229"
          data-name="Ellipse 229"
          cx="4.133"
          cy="4.133"
          r="4.133"
          transform="translate(92.81 83.818)"
          fill="#263238"
        />
        <path
          id="Path_3864"
          data-name="Path 3864"
          d="M321.646,323.094l-.228-.433c-.02-.039-2.156-3.983-6.452-3.983a5.119,5.119,0,0,0-4.915,3.873l-.114.475-.948-.231.114-.472c0-.046,1.163-4.622,5.863-4.622,4.885,0,7.221,4.325,7.315,4.508l.228.433Z"
          transform="translate(-235.103 -230.352)"
          fill="#263238"
        />
      </g>
    </svg>
  );
}
